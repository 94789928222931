import useApiContext from 'hooks/useApiContext';
import useRepo from 'hooks/useRepo';
import React, { useEffect } from 'react';
import { ModalProvider } from 'contexts/ModalContext';
import { useNavigate } from 'react-router-dom';
import { M } from 'TOMA';
import Toma from 'TOMA/Toma';
import { ApiProvider } from './ApiContext';
import AppRouter from './router/AppRoutes';
import routes from 'router/routes';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import common_en from './i18n/en/common.js';
import login_en from './i18n/en/login.js';
import signup_en from './i18n/en/signup.js';
import home_en from './i18n/en/home.js';
import product_en from './i18n/en/product.js';
import admin_en from './i18n/en/admin.js';
import forgotPassword_en from './i18n/en/forgotPassword.js';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      ...common_en,
      ...login_en,
      ...signup_en,
      ...home_en,
      ...product_en,
      ...admin_en,
      ...forgotPassword_en,
    },
  },
});

function App(): JSX.Element {
  const { userRepository } = useApiContext();
  const navigate = useNavigate();

  const { loading } = useRepo(async () => await userRepository.reconnectUser());

  useEffect(() => {
    document.addEventListener(
      'gohome',
      () => {
        const { pathname } = window.location;
        const pathRef = pathname === '/' ? '' : `#${pathname}`;
        if (!pathname.startsWith('/login')) {
          navigate(`${routes.login.to()}${window.location.search}${pathRef}`, {
            replace: true,
          });
        }
      },
      false
    );
  }, []);

  return (
    <I18nextProvider i18n={i18next}>
      <M.Loading loading={loading} fullWiewport>
        <Toma />
        <ModalProvider>
          <ApiProvider>
            <AppRouter />
          </ApiProvider>
        </ModalProvider>
      </M.Loading>
    </I18nextProvider>
  );
}

export default App;
