import React, { useState, useEffect } from 'react';
import { T, O, M, A } from 'TOMA';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import useApiContext from 'hooks/useApiContext';
import {
  Ext,
  iExhibitorRepository,
} from 'services/Exhibitor/Exhibitor.repository';
import { iExhibitorRest, iRest } from 'services/Exhibitor/Exhibitor.type';
import packageRequest from 'helpers/packageRequest';
import useRepo from 'hooks/useRepo';

interface Props {
  name?: string;
  children: React.ReactNode;
  ids: number[];
}

function Extraction({ children, name, ids }: Props): JSX.Element {
  const {
    exhibitorRepository,
    productRepository,
    codificationRepository,
    mediaRepository,
  } = useApiContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [data, setData] = useState<
    Array<Array<string | undefined>> | undefined
  >(undefined);

  const { response: codifTypeVin } = useRepo(
    async () => await codificationRepository.get('type_vin')
  );
  const { response: codifClassification } = useRepo(
    async () => await codificationRepository.get('classification')
  );
  const { response: codifTypeProduit } = useRepo(
    async () => await codificationRepository.get('type_produit')
  );
  const { response: codifMillesime } = useRepo(
    async () => await codificationRepository.get('millesime')
  );
  const { response: codifTypeCulture } = useRepo(
    async () => await codificationRepository.get('type_culture')
  );
  const { response: codifPrices } = useRepo(
    async () => await codificationRepository.get('prices')
  );
  const { response: codifAppellation } = useRepo(
    async () => await codificationRepository.get('appellation')
  );
  const { response: codifCouleur } = useRepo(
    async () => await codificationRepository.get('couleur')
  );

  const { response: codifDenominationIgp } = useRepo(
    async () => await codificationRepository.get('denomination_igp')
  );
  const { response: codifDenomination } = useRepo(
    async () => await codificationRepository.get('denomination')
  );
  const { response: codifRegion } = useRepo(
    async () => await codificationRepository.get('region')
  );
  const { response: codifCepage } = useRepo(
    async () => await codificationRepository.get('cepage')
  );

  async function processing(): Promise<void> {
    if (loading) return;
    setLoading(true);

    const exhibitorList = await packageRequest<Ext>(
      async (id) => {
        const result = await exhibitorRepository.extractUserExhibitor(id);
        return result;
      },
      ids.map((e) => e.toString()),
      (p) => setProgress(p)
    );

    const dataExhibitor: Array<Array<string | undefined>> = exhibitorList
      .sort((a, b) => a.exhibitor.id - b.exhibitor.id)
      .filter((e) => e.exhibitor.rgpd)
      .map(({ exhibitor, products }) => {
        const ex = [
          `${exhibitor.id}`,
          exhibitor.raison_sociale,
          exhibitor.siret,
          exhibitor.nom_societe,
          exhibitor.logo_url,
          (exhibitor.description_en || '').replace(/\n|\r/g, ' '), // remove line breaks,
          exhibitor.adresse_postale,
          exhibitor.site_web,
          exhibitor.code_postal,
          exhibitor.ville,
          exhibitor.pays,
          exhibitor.contact_revendeur_firstname,
          exhibitor.contact_revendeur_lastname,
          exhibitor.contact_revendeur_tel,
          exhibitor.contact_revendeur_email,
          exhibitor.locked
            ? 'Transmis'
            : exhibitor.raison_sociale
            ? 'Enregistré'
            : '-',
          `${exhibitor.hall} ${exhibitor.stand}`,
          exhibitor.year,
        ];

        return products
          .filter((p) => p !== null)
          .map((product) => [
            ...ex,
            product?.producer,
            product?.name,
            (product?.description_en ?? '').replace(/\n|\r/g, ' '), // remove line breaks
            codifClassification?.find(
              ({ topicCode }) => topicCode === product?.classification
            )?.label ?? '',
            `${
              codifAppellation?.find(
                ({ topicCode }) => topicCode === product?.appellation
              )?.label ??
              codifDenomination?.find(
                ({ topicCode }) => topicCode === product?.denomination
              )?.label ??
              codifDenominationIgp?.find(
                ({ topicCode }) => topicCode === product?.denomination_igp
              )?.label ??
              ''
            }`,
            codifTypeProduit?.find(
              ({ topicCode }) => topicCode === product?.type_produit
            )?.label ?? '',
            codifTypeVin?.find(
              ({ topicCode }) => topicCode === product?.type_vin
            )?.label ?? '',
            codifCouleur?.find(
              ({ topicCode }) => topicCode === product?.couleur
            )?.label ?? '',

            codifMillesime?.find(
              ({ topicCode }) => topicCode === product?.millesime
            )?.label ?? '',
            codifTypeCulture?.find(
              ({ topicCode }) => topicCode === product?.type_culture
            )?.label ?? '',
            '',
            codifPrices?.find(({ topicCode }) => topicCode === product?.price)
              ?.label ?? '',
            product?.photo_1_url,

            product?.cepage
              .split(';')
              .map(
                (tc) =>
                  codifCepage?.find(({ topicCode }) => topicCode === tc)
                    ?.label ?? ''
              )
              .join(', '),

            codifRegion?.find(({ topicCode }) => topicCode === product?.region)
              ?.label ?? '',

            product?.technical_file_url,
          ]);
      })
      .flat()
      .filter((e) => e.length > 0);

    setData([
      [
        'ID',
        'RAISON SOCIALE',
        'SIRET',
        'ENSEIGNE / MARQUE',
        'LOGO',
        'COMPANY DESCRIPTION EN',
        'ADRESSE 1',
        'SITE WEB',
        'CP',
        'VILLE',
        'PAYS',
        'NOM CONTACT',
        'PRÉNOM CONTACT',
        'TÉLÉPHONE CONTACT',
        'EMAIL CONTACT',
        'STATUT',
        'HALL + STAND',
        'ANNEE',

        'Producer',
        'Nom du produit',
        'Description EN',
        'Catégorie',
        'Appellation / Dénomination',
        'Type1',
        'Type2',
        'Type3',
        'Millésime',
        'Type de culture',
        'Médaille',
        'Fourchette de prix',
        'Visuel du produit',
        'Cépage',
        'Région',
        'Fiche technique',
      ],
      ...dataExhibitor,
    ]);

    setLoading(false);
    setProgress(0);
  }

  if (data) {
    return (
      <CSVLink
        style={{ textDecoration: 'none' }}
        filename={`export${name ? `-${name}` : ''}-${format(
          new Date(),
          'dd-MM-yyyy-HH-mm-ss'
        )}.csv`}
        separator={';'}
        enclosingCharacter={'"'}
        data={data}
        onClick={() => setData(undefined)}
      >
        <T.Block px="40" py="10" bg="primary" borderRadius="5" mb="15">
          <A.Text color="white" textDecoration="none">
            Télécharger
          </A.Text>
        </T.Block>
      </CSVLink>
    );
  }

  return (
    <A.Button
      type="button"
      onClick={async () => await processing()}
      loading={loading}
      progress={progress}
    >
      {children}
    </A.Button>
  );
}

export default Extraction;
