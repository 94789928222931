export default {
  home: {
    title: 'Data registration',
    deadline: 'Registration deadline:',
    form: {
      success: 'Form submitted successfully!',
      folderContact: {
        title: 'Contact for this file',
        subtitle: 'Information not visible on communication materials',
        firstname: 'First name',
        lastname: 'Last name',
        phone: 'Contact phone',
        mobile: 'Contact mobile',
        email: 'Contact email / login',
        password: 'Password',
      },
      qrcode: {
        title: 'QRCode company profile',
        text: 'View your company profile online',
        download: 'Download the QRCode',
      },
      companyInfo: {
        title: 'Company information',
        company: 'Company name',
        commercialName: 'Trade name',
        commercialNameInfo:
          'As they will appear on the different communications media',
        address: 'Address',
        zipcode: 'Post code',
        city: 'City',
        country: 'Country',
        phone: 'General phone',
        email: 'General company email',
        website: 'Website',
        companyType: 'Type of company',
        siret: 'ABN',
        domainSize: 'Domain size',
        domainSizeDetail: '(in ha between 0 and 100,000)',
        yearProduct: 'Annual production',
        yearProductDetail: '(in number of bottles between 0 and 10,000,000)',
        exportPercent: 'Export percentage',
        exportMarket: 'Your export markets (multiple choices possible)',
        exportMarketWish:
          'Export development wishes? (multiple choices possible)',
        compagnyPresentation:
          'Company presentation in english (500 characters maximum)',
        webLogo: 'Web company logo',
        webLogoFormat: '(jpg, png, 5MB maximum)',
        HdLogo: 'HD Logo',
        HdLogoFormat: '(pdf, eps, ai: 10MB maximum)',
      },
      galery: {
        title: 'Gallery',
        photos: 'Company photos',
        photosDetail: '(5 items, jpg, png, 5MB maximum)',
        videoLink: 'Company Youtube video link',
      },
      contact: {
        title: 'Contact',
        titleDetail: '(Information visible on communication materials)',
        firstname: 'First name',
        lastname: 'Last name',
        mobile: 'Mobile phone',
        phone: 'Phone',
        email: 'Email',
      },
      products: {
        title: 'Your products',
        info: 'Possibility to present up to 30 references',
        addProduct: 'Add a product',
        product: 'Product',
        edit: 'Edit',
        checkbox:
          'The personal data collected is processed by Vins De Loire. It is necessary for the organization of the event or to send you any document and/or service. Vins De Loire may use this data to offer you products and/or services useful for the development of your business. To exercise your rights or if you do not wish to receive proposals from Vins De Loire regarding its actions, services, events, please click here. To learn more: Personal Data Protection Charter.',
      },
      buttons: {
        save: 'Save',
        locked: 'Locked',
        submit: 'Save and submit',
      },
      asterix: {
        '*': 'You can reconnect and sequence the information entry',
        '**': 'Your entry is finalized, elements can no longer be modified.',
      },
    },
  },
};
