import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CustomLink } from 'components/CustomLink';
import useApiContext from 'hooks/useApiContext';
import routes from 'router/routes';
import { T, O, M, A } from 'TOMA';
import s from './Header.module.scss';
import { eRoles } from 'services/User/User.repository';
import { useTranslation } from 'react-i18next';

function Header(): JSX.Element {
  const navigate = useNavigate();
  const { userRepository } = useApiContext();
  const user = userRepository.getUser();
  const { t } = useTranslation();

  return (
    <>
      <T.Block
        bg="white"
        style={{ boxShadow: '0 10px 10px rgba(0,0,0,0.05)' }}
        className={s.Header}
      >
        <T.Container py="20">
          <T.Flex gap="2" halign="space-between" valign="center">
            <T.Block>
              <Link to={routes.home.path}>
                <T.Flex valign={'center'}>
                  <A.Image
                    src="/Logo-Vins-de-Loire.png"
                    height="70"
                    width="auto"
                  />
                </T.Flex>
              </Link>
            </T.Block>
            {user && (
              <T.Col flex="1 0 auto" className={s.Header__desk}>
                <CustomLink
                  to={routes.home.path}
                  color="black"
                  textDecoration="none"
                >
                  {t('common:header.nav.home')}
                </CustomLink>{' '}
              </T.Col>
            )}
            <T.Flex gap="2" valign={'center'}>
              {user ? (
                <A.Button
                  appearance="link"
                  onClick={() => {
                    userRepository.logout().finally(() => {
                      navigate(routes.login.to(), { replace: true });
                    });
                  }}
                  color="black"
                  textDecoration="none"
                >
                  <div className={s.Header__desk}>
                    {t('common:header.nav.signout')}
                  </div>
                  <div className={s.Header__mob}>
                    <A.Icon icon="box-arrow-right" fontSize="22" />
                  </div>
                </A.Button>
              ) : (
                <>
                  <CustomLink
                    to={routes.login.path}
                    color="black"
                    textDecoration="none"
                  >
                    {t('common:header.nav.signin')}
                  </CustomLink>
                  <CustomLink
                    to={routes.signup.path}
                    color="black"
                    textDecoration="none"
                  >
                    {t('common:header.nav.signup')}
                  </CustomLink>
                </>
              )}
            </T.Flex>
          </T.Flex>
        </T.Container>
      </T.Block>
      {user?.role !== eRoles.ADMIN && (
        <div className={s.Header__encart}>
          <T.Block>
            <T.Container maxWidth="900px">
              <T.Flex halign="space-between" py="50" gap={4}>
                <T.Col flex="0 0 auto">
                  {/* <T.Flex gap={1} valign="center" mb="10">
                    <A.Icon icon="calendar2-week-fill" color="white" />
                    <T.Block>
                      <A.Text color="white" fontSize="18">
                        {t('common:subheader.date')}
                      </A.Text>
                    </T.Block>
                  </T.Flex> */}
                  <T.Flex gap={1} valign="center">
                    <A.Icon icon="geo-fill" color="white" />
                    <T.Block>
                      <A.Text color="white" fontSize="18">
                        {t('common:subheader.location')}
                      </A.Text>
                    </T.Block>
                  </T.Flex>
                </T.Col>
              </T.Flex>
            </T.Container>
          </T.Block>
        </div>
      )}
    </>
  );
}

export default Header;
