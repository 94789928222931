import React, { useState, useContext, useEffect } from 'react';
import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { T, O, M, A } from 'TOMA';
import { FormContext } from '../Form';
import { useTranslation } from 'react-i18next';
import { CustomLink } from 'components/CustomLink';

const MAX_SIZE = 5;

type iInputFile<T extends FieldValues> = {
  control: Control<T>;
  name: string;
  id?: string;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  multiple?: boolean;
  error?: React.ReactNode;
  onInit: (e: string) => Promise<any>;
  onChange: (e: File) => Promise<any>;
  onRemove: (e: string) => Promise<any>;
  information?: React.ReactNode;
  fileName?: string;
  maxFileSize?: number;
  accept?: string;
  download?: boolean;
} & UseControllerProps<T>;

export function InputFile<T extends FieldValues>({
  name,
  control,
  label,
  required,
  disabled,
  onInit,
  onChange: onChangeProp,
  onRemove,
  information,
  maxFileSize = MAX_SIZE,
  accept,
  download,
}: iInputFile<T>): JSX.Element {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [mediaSrc, setMediaSrc] = useState<string | undefined>(undefined);
  const translation = useContext(FormContext);
  const {
    field: { onChange, value, ref },
    fieldState: { isTouched },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
    rules: {
      required: required === true ? translation.required : required,
    },
  });

  const path = name.split('.');
  let key;
  let error: any = errors;
  while ((key = path.shift())) {
    if (!Number.isNaN(parseInt(key, 10))) {
      key = parseInt(key, 10);
    }
    if (!error[key]) {
      error = undefined;
      break;
    }
    error = error[key];
  }
  const errorMessage = (isTouched || isSubmitted) && error && error.message;
  const [err, setErr] = useState(errorMessage);

  useEffect(() => {
    setErr(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (value) {
      setLoading(true);
      onInit(value)
        .then(({ filename, src }) => {
          if (src) setMediaSrc(src);
          if (filename) setFileName(filename);
        })
        .finally(() => setLoading(false));
    }
  }, [value]);

  function onInputChange(files: FileList | null | undefined): void {
    if (files) {
      const file = files[0];
      setLoading(true);
      setFileName(file.name);
      if (file.size > maxFileSize * 1_048_576) {
        setLoading(false);
        setErr(t('common:form.error.fileTooLarge', { size: maxFileSize }));
        return;
      }
      onChangeProp(file)
        .then(({ mediaId, src }) => {
          setErr(undefined);
          setMediaSrc(src);
          onChange(mediaId);
        })
        .catch((err) => {
          setErr(err.message);
          onChange(undefined);
        })
        .finally(() => setLoading(false));
    }
  }

  async function onInputRemove(): Promise<void> {
    await onRemove(value);
    setFileName(undefined);
    onChange(undefined);
  }

  return (
    <>
      <A.InputFile
        noFileSelected={t('common:form.noFileSelected')}
        buttonLabel={t('common:form.chooseFile')}
        label={label}
        name={name}
        loading={loading}
        fileName={fileName}
        // onChange={onInputChange}
        onFileChange={onInputChange}
        onRemove={onInputRemove}
        disabled={disabled}
        error={err}
        information={information}
        required={required}
        accept={accept}
        ref={ref}
      />
      {download && mediaSrc && fileName && (
        <T.Flex valign="center">
          <A.Icon icon="download" fontSize="14" mr="5" />
          <CustomLink
            to={mediaSrc}
            download={fileName}
            color="grey-70"
            fontSize="14"
            fw="normal"
          >
            <span>
              {t('common:form.downloadFile')} {fileName}
            </span>
          </CustomLink>
        </T.Flex>
      )}
    </>
  );
}
InputFile.defaultProps = {
  id: undefined,
  label: undefined,
  disabled: undefined,
  required: undefined,
  information: undefined,
};
