export default {
  admin: {
    title: 'List of exhibitors',
    deadline: 'Deadline',
    unlockAll: 'Unlock all',
    lockAll: 'Lock all',
    visibleAll: 'All visible',
    hiddenAll: 'Hide all',
    filter: 'Filter',
    simpleExtract: 'Simple extract',
    new: 'New',
    status: 'Status',
    number: 'Number',
    activate: 'Activated',
    locked: 'Locked',
    visible: 'Visible',
    company: 'Company name',
    qrcode: 'QR code',
    commercialName: 'Trade name',
    year: 'Year',
    standNumber: 'Booth number',
    edit: 'Edit',
    removeExhibitor: 'Remove exhibitor "{{exhibName}}" ?',
    transmitted: 'Transmitted',
    saved: 'Saved',
    progress: 'In progress',
    pending: 'Pending',
    confirm: 'Confirmed',
    cancel: 'Cancel',
    booth: 'Booth',
    hall: 'Hall',
  },
};
