import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import routes from 'router/routes';
import { eRoles } from 'interfaces/Roles';
import Layout from 'components/Layout';

import Exhibitor from 'pages/Exhibitor';
import ExhibitorList from 'pages/ExhibitorList';
import Login from 'pages/Login';
import SignUp from 'pages/SignUp';
import useApiContext from 'hooks/useApiContext';
import Product from 'pages/Product';
import Codif from 'pages/Codif';
import Confirmation from 'pages/Confirmation';
import ForgotPassword from 'pages/ForgotPassword';
import UpdatePassword from 'pages/UpdatePassword';

export default function AppRoutes(): JSX.Element {
  useLocation();
  const { userRepository } = useApiContext();
  const user = userRepository.getUser();

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path={routes.login.path} element={<Login />} />
        <Route path={routes.signup.path} element={<SignUp />} />
        <Route path={routes.forgotPassword.path} element={<ForgotPassword />} />
        <Route path={routes.confirmation.path} element={<Confirmation />} />
        <Route path={routes.updatePassword.path} element={<UpdatePassword />} />

        <Route path={routes.codif.path} element={<Codif />} />

        {user?.role === eRoles.EXHIBITOR && (
          <Route element={<Exhibitor />}>
            <Route path={routes.home.path} />
            <Route path={routes.product.path} element={<Product />} />
            <Route path={routes.productId.path} element={<Product />} />
          </Route>
        )}

        {user?.role === eRoles.ADMIN && (
          <Route element={<ExhibitorList />}>
            <Route path={routes.home.path} />
            <Route path={routes.exhibitor.path} element={<Exhibitor />} />
            <Route path={routes.productAdmin.path} element={<Product />} />
            <Route path={routes.productIdAdmin.path} element={<Product />} />
          </Route>
        )}

        <Route path="/" element={<Navigate to={routes.login.path} />} />
        <Route
          path={routes.home.path}
          element={<Navigate to={routes.login.path} />}
        />
        <Route path="*" element={<div>404</div>} />
      </Route>
    </Routes>
  );
}
