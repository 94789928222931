import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useApiContext from 'hooks/useApiContext';
import Form, { InputPhone, InputText } from 'form';
import { T, O, M, A } from 'TOMA';
import { useTranslation } from 'react-i18next';

export interface iFormSignUp {
  firstname: string;
  lastname: string;
  phone: string;
  raison_sociale: string;
  siret: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

function SignUp(): JSX.Element {
  const { userRepository } = useApiContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string | undefined>();
  const { t } = useTranslation();

  const schema = yup.object({
    firstname: yup.string().required(t('common:form.error.required')),
    lastname: yup.string().required(t('common:form.error.required')),
    raison_sociale: yup.string().required(t('common:form.error.required')),
    siret: yup.string().required(t('common:form.error.required')),
    email: yup
      .string()
      .email(t('common:form.error.email'))
      .required(t('common:form.error.required')),
    password: yup
      .string()
      .pipe(
        yup
          .string()
          .min(
            8,
            `<b>${t('common:form.error.minCharacters', { length: 8 })}</b>`
          )
          .minLowercase(1, `<b>${t('common:form.error.oneLowerCase')}</b>`)
          .minUppercase(1, `<b>${t('common:form.error.oneUpperCase')}</b>`)
          .minNumbers(1, `<b>${t('common:form.error.oneNumber')}</b>`),
        t('common:form.error.passwordMustContain'),
        t('common:form.error.and')
      )
      .required(t('common:form.error.required')),
    passwordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        t('common:form.error.passwordMustMatch')
      )
      .required(t('common:form.error.required')),
  });

  function handleSubmit({
    firstname,
    lastname,
    phone,
    raison_sociale,
    siret,
    email,
    password,
  }: iFormSignUp): void {
    setLoading(true);
    userRepository
      .register(
        firstname,
        lastname,
        phone,
        raison_sociale.trim(),
        siret,
        email,
        password
      )
      .then((e) => {
        setSuccess(t('signup:form.success'));
      })
      .catch((err: Error) => {
        setError(err.message);
      })
      .finally(() => setLoading(false));
  }

  return (
    <T.Container py="50">
      <T.Block pb="50">
        <A.Title as="h1" fontSize="48" textAlign="center" fw="black">
          {t('signup:title')}
        </A.Title>
      </T.Block>
      <T.Block pb="50">
        <A.Title as="h1" fontSize="32" textAlign="center" fw="black">
          {t('signup:subtitle')}
        </A.Title>
      </T.Block>
      <T.Block bg="grey-10" borderRadius="5" mw="medium" mx="auto" p="80">
        {!success && (
          <>
            <T.Block pb="50">
              <A.Text fontSize="16">
                <b>{t('signup:form.text.bold')}</b>
                {t('signup:form.text.normal')}
              </A.Text>
            </T.Block>
            <Form<iFormSignUp>
              onSubmit={async (data) => handleSubmit(data)}
              resolver={yupResolver(schema)}
            >
              {({ control }) => (
                <div>
                  <T.Block mb="40">
                    <InputText
                      label={t('signup:form.labels.firstname')}
                      name="firstname"
                      type="text"
                      control={control}
                      required
                    />
                    <InputText
                      label={t('signup:form.labels.lastname')}
                      name="lastname"
                      type="text"
                      control={control}
                      required
                    />
                    <InputPhone
                      label={t('signup:form.labels.phone')}
                      name="phone"
                      control={control}
                      placeholder="+33 1 23 45 67 88"
                    />
                    <InputText
                      label={t('signup:form.labels.companyName')}
                      name="raison_sociale"
                      type="text"
                      control={control}
                      required
                    />
                    <InputText
                      label={t('signup:form.labels.siret')}
                      name="siret"
                      type="text"
                      control={control}
                      required
                    />
                    <InputText
                      label={t('signup:form.labels.email')}
                      name="email"
                      type="text"
                      control={control}
                      required
                    />
                    <InputText
                      label={t('signup:form.labels.password')}
                      name="password"
                      type="password"
                      control={control}
                      required
                      autocomplete="new-password"
                    />
                    <InputText
                      label={t('signup:form.labels.passwordConfirmation')}
                      name="passwordConfirmation"
                      type="password"
                      control={control}
                      required
                      autocomplete="new-password"
                    />
                  </T.Block>

                  <A.Alert alert="error" side="⚠️" mb="30">
                    {error && <A.InnerHTML html={error} />}
                  </A.Alert>

                  <A.Button type="submit" loading={loading} center>
                    {t('signup:form.validate')}
                  </A.Button>
                </div>
              )}
            </Form>
            <A.Text fontSize="14">* {t('signup:form.asterix.required')}</A.Text>
            <A.Text fontSize="14">** {t('signup:form.asterix.info')}</A.Text>
          </>
        )}

        {success && (
          <>
            <A.Text fontSize="48" textAlign="center" mb="30">
              🎉
            </A.Text>
            <A.Text fontSize="18" textAlign="center" fw="bold">
              {success}
            </A.Text>
          </>
        )}
      </T.Block>
    </T.Container>
  );
}

export default SignUp;
