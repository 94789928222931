export default {
  signup: {
    title: 'Importer registration for Loire Wines Trade Tasting',
    subtitle: 'Account creation request:',
    form: {
      text: {
        bold: 'Contact for login creation > ',
        normal: 'Information not visible** on communication materials',
      },
      labels: {
        firstname: 'First name',
        lastname: 'Last name',
        phone: 'Phone',
        companyName: 'Company name',
        siret: 'ABN',
        email: 'Email / login',
        password: 'Choose your password',
        passwordConfirmation: 'Confirm your password',
      },
      validate: 'Validate',
      asterix: {
        required: 'Required information',
        info: 'The information requested above is used to create the account and login credentials for your company. This information will not be visible; it is kept for file management. (1 email address = 1 company account)',
      },
      success:
        'You will receive an email in a few minutes to confirm your account. Please, check your spam folder',
    },
  },
};
