export default {
  product: {
    title: 'Product registration',
    product: 'Product',
    newProduct: 'New product',
    addProduct: 'Add a product',

    form: {
      producer: 'Producer',
      productName: 'Product name',
      productDescription:
        'Product description in english (500 characters maximum)',
      productVisual: 'Pics of product',
      productVisualInfo:
        'Your product visual: please attach a pics of the presented product bottle',
      productInfo: {
        title: 'Product information',
        productType: 'Product type',
        vineType: 'Wine type',
        color: 'Color',
        region: 'Region',
        classification: 'Classification',
        appellation: 'AOC/AOP Appellation',
        denominationIgp: 'IGP Denomination',
        denomination: 'Other Denomination: VDF / other wines / Spirits',
        typeCulture: 'Culture type',
        millesime: 'Vintage',
        cepage: 'Grapes (multiple choices possible)',
        format: 'Format',
        price: 'LUC',
        alcool: 'Alcohol percentage',
        typeVendange: 'Harvest type',
        allergene: 'Allergen(s)',
        arome: 'Tasting flavors',
        technicalFile: 'Technical file',
      },
      success: 'Product successfully registered',
      save: 'Save',
      cancel: 'Cancel',
      confirm: 'Confirm',
      confirmRemoveProduct: 'Remove the product?',
      remove: 'Remove',
    },
  },
};
