import Header from 'components/Header/Header';
import React from 'react';
// import { Helmet } from 'react-helmet';
import { T, O, M, A } from 'TOMA';
import { Outlet } from 'react-router-dom';
import styles from './Layout.module.scss';
import { CustomLink } from 'components/CustomLink';
import { useTranslation } from 'react-i18next';

export interface iSideBarContent {
  title?: string;
  text?: string;
}

function Layout(): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      {/* <Helmet></Helmet> */}
      <div className={styles.Layout}>
        <Header />
        <main className={styles.Layout__main}>
          <Outlet />
        </main>
        <footer className={styles.Layout__footer}>
          <T.Container bg="grey-05">
            <T.Block py="50">
              <A.Text color="grey-80">
                {t('common:footer')}{' '}
                <CustomLink
                  className={styles.Layout__link}
                  to="mailto:lmargetic@hopscotchgroupe.com"
                  color="grey-80"
                >
                  lmargetic@hopscotchgroupe.com
                </CustomLink>
              </A.Text>
            </T.Block>
          </T.Container>
        </footer>
      </div>
    </>
  );
}

Layout.defaultProps = {
  title: null,
  content: null,
};

export default React.memo(Layout);
