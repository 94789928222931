import React, { useState } from 'react';
import useApiContext from 'hooks/useApiContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Form, { InputText } from 'form';
import { T, O, M, A } from 'TOMA';
import { useNavigate } from 'react-router-dom';
import { CustomLink } from 'components/CustomLink';
import { yupCustomMethods } from 'form/helpers/yupCustomMethods';
import routes from 'router/routes';
import { useTranslation } from 'react-i18next';

yupCustomMethods(yup);

export interface iFormLogin {
  email: string;
  password: string;
}

function Login(): JSX.Element {
  const navigate = useNavigate();
  const { userRepository } = useApiContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { t } = useTranslation();

  const schema = yup.object({
    email: yup
      .string()
      .email(t('common:form.error.email'))
      .required(t('common:form.error.required')),
    password: yup.string().required(t('login:form.error.required')),
  });

  function handleSubmit({ email, password }: iFormLogin): void {
    setLoading(true);
    userRepository
      .login(email, password)
      .then((e) => {
        navigate(routes.home.to(), { replace: true });
      })
      .catch((err: Error) => {
        setError(err.message);
      })
      .finally(() => setLoading(false));
  }

  return (
    <T.Container py="50">
      <T.Block pb="50">
        <A.Title as="h1" fontSize="48" textAlign="center" fw="black">
          {t('login:title')}
        </A.Title>
      </T.Block>
      <T.Block pb="20">
        <A.Title as="h2" fontSize="32" textAlign="center" fw="black">
          {t('login:signIn')} :
        </A.Title>
      </T.Block>
      <T.Block mw="normal" mx="auto">
        <Form<iFormLogin>
          onSubmit={async (data) => handleSubmit(data)}
          resolver={yupResolver(schema)}
        >
          {({ control }) => (
            <div>
              <T.Block mb="40">
                <InputText
                  label={t('login:email')}
                  name="email"
                  type="email"
                  inputMode="email"
                  control={control}
                  autocomplete="username"
                />
                <InputText
                  label={t('login:password')}
                  name="password"
                  type="password"
                  control={control}
                  autocomplete="current-password"
                />
                <CustomLink
                  to={routes.forgotPassword.path}
                  textAlign="end"
                  fontSize="14"
                >
                  {t('login:forgotPassword')}
                </CustomLink>
              </T.Block>

              <A.Alert alert="error" side="⚠️" mb="15">
                {error}
              </A.Alert>

              <T.Flex valign="center" halign="center">
                <A.Button type="submit" loading={loading} center>
                  {t('login:signIn')}
                </A.Button>
                <CustomLink
                  to={routes.signup.path}
                  mb="15"
                  ml="auto"
                  mr="auto"
                  color="black"
                  textDecoration="underline"
                >
                  {t('login:signUp')}
                </CustomLink>
              </T.Flex>
            </div>
          )}
        </Form>
      </T.Block>
    </T.Container>
  );
}

export default Login;
